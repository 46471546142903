import styled from 'styled-components/macro';

export const Container = styled.div``;

export const TwoColumnGrid = styled.main`
  display: grid;
  grid-template-columns: 1fr 4fr;
  margin: 0 auto;
  max-width: 1400px;
  margin-top: 80px;

  
  @media (max-width: ${({ theme: { breakpoints } }) =>
  breakpoints.mobile}){
    max-width: auto;
    grid-template-columns: 1fr;
    margin-top: 0px;
  }
  @media (min-width: ${({ theme: { breakpoints } }) =>
  breakpoints.tablet}) and (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.desktop}) {
    max-width: 1000px;
    grid-template-columns: 1fr 2fr;
    margin-top: 80px;
  }
`;

export const Side = styled.div`
  padding: 15px;
  box-sizing: border-box;

  @media only screen and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.tablet}) {
    justify-content: center;
    align-content: baseline;
  }
`;

export const Main = styled.main``;

export const MainHeader = styled.main`
  display: grid;
  grid-template-columns: 2fr 1fr;
  justify-content: end;
  padding: 0 15px;

  @media only screen and (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.mobile}) {
    justify-content: center;
    align-content: baseline;
    font-size: 14px;
  }
`;
