import styled from 'styled-components/macro';

export const Header = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media only screen and (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.mobile}) {
    line-height: 40px;
  }
`;

export const Title = styled.h3`
  font-size: 16px;
  font-weight: 600;
  line-height: 35px;
  color: ${({ theme }) => theme.colors.tertiary};
  padding-left: 60px;
  margin: 20px -50px;
  width: 100%;
  display: flex;
  @media only screen and (max-width: ${({ theme: { breakpoints } }) =>
    breakpoints.mobile}) {
      margin: 0px -50px 30px;
      line-height: 25px;
      text-align: center;
      font-size: 14px;
  }

  @media only screen and (min-width: ${({ theme: { breakpoints } }) =>
    breakpoints.tablet}) and (max-width: ${({ theme: { breakpoints } }) =>
  breakpoints.desktop}) {
    padding: 0px 0px 0px 0px;
    margin-left: 15px;
  }
}
`;

export const CardContent = styled.main`
  margin-top: 0;
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  padding: 0%;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  scroll-behavior: smooth;

  @media only screen and (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.tablet}) {
    flex-direction: column;
    padding: 10px;
    width: 85%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
  }
`;
