import styled from 'styled-components/macro';

export const Container = styled.div`
  flex: 1;
  display: flex;
  padding: 10px;
  width: 100%;
  position: relative;
  align-items: center;
  border-radius: 10px;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #ffffff;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  height: 90px;
  transition: ease-in-out 0.5s;

  &:hover {
    transform: translateY(-10px);
  }

  @media only screen and (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.mobile}) {
    width: 100%;
    height: 130px;
    border-radius: 15px;
    padding: 0px 15px 0px 15px;
    margin-top: -30px;
  }

  @media only screen and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.tablet}) and (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.desktop}) {
    width: 80%;
    padding: 20px;
  }
`;

export const CardIcon = styled.div`
  display: flex;
  padding: 5px;
  align-items: center;
  aspect-ratio: 1;
  border-radius: 50%;
  width: 25%;
  flex-direction: column;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.white};

  @media only screen and (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.mobile}) {
    padding: 20px;
  }
`;

export const CardContentMain = styled.main`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
`;

export const Title = styled.h1`
  font-size: 14px;
  font-style: normal;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 500;
  line-height: 48px;

  @media only screen and (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.mobile}) {
    font-size: 12px;
    line-height: 1.2; 
  }
`;
