
import { ReactChild, ReactFragment, ReactPortal } from 'react';
import * as S from './style';

import PropTypes from 'prop-types'


const Card = (props: { IconAlt: string | undefined; Icon: string | undefined; rootClassName: any; Header: boolean | ReactChild | ReactFragment | ReactPortal | null | undefined; }) => {

  return (
    <S.Container>
      <S.CardIcon>
        <img alt={props.IconAlt} src={props.Icon} width={"35px"} />
      </S.CardIcon>
      <S.CardContentMain className={props.rootClassName}>
        <S.Title>{props.Header}</S.Title>
      </S.CardContentMain>
    </S.Container>
  );
};

Card.defaultProps = {
  rootClassName: 'card-content',
  Icon: '/Icons/group%201316-200w.png',
  IconAlt: 'image',
  Header: 'Sima Mosbacher',
}

Card.propTypes = {
  rootClassName: PropTypes.string,
  Icon: PropTypes.string,
  IconAlt: PropTypes.string,
  Header: PropTypes.string,
}


export default Card;
